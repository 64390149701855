@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';
@import 'settings';
@import 'font-face';
@import 'react-redux-toastr/src/styles/index';
@import './notification-toast.scss';
*,
::after,
::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
// iframe{
//   display: none !important;
// }

.redux-toastr {
  .bottom-left {
    width: 584px;
    max-width: 100%;
    & > div {
      padding: 8px 10px;
    }
  }
  .toastr {
    opacity: 1;
    min-height: auto;
    box-shadow: none;
    &.rrt-error {
      background-color: #db6a64;
    }
    &.rrt-success {
      background-color: #6fcf97;
    }
    &:hover:not(.rrt-message) {
      box-shadow: none;
    }
    & > div {
      display: flex;
    }
    .rrt-left-container {
      display: none;
    }
    .rrt-title {
      display: none;
    }
    .rrt-text {
      color: $white;
      font-size: 14px;
      font-family: $font-family-sans-serif;
    }
    .rrt-middle-container {
      margin: 0;
      width: 100%;
      padding: 16px 18px;
    }
    .close-toastr {
      position: relative;
      opacity: 1;
      color: $white;
      width: auto;
      font-size: 15px;
      padding: 0;
      font-weight: 700;
    }
  }
}

.itemTooltip {
  position: relative;

  .tooltiptext {
    visibility: hidden;
    width: 175px;
    background-color: #fff;
    color: $gray-900;
    text-align: left;
    border-radius: 6px;
    padding: 8px 10px;
    position: absolute;
    z-index: 9;
    top: -9px;
    text-transform: none;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s;
    border: 1px solid #cbcbcb;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    transform: translateY(-100%);

    &::after {
      content: '';
      position: absolute;
      bottom: -7px;
      left: 20px;
      width: 12px;
      height: 12px;
      background-color: $white;
      border-width: 1px;
      border-style: solid;
      border-color: transparent #cbcbcb #cbcbcb transparent;
      transform: rotate(45deg);
    }
  }

  &:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
}
.tooltip:disabled {
  opacity: 1;
  // span:not() {
  //   opacity: 1;
  // }
  color: #c5c5c5 !important;
}
.h-adjust {
  @include media-breakpoint-up(lg) {
    min-height: 135px;
  }
}
.tooladjust {
  .tooltiptext {
    &::after {
      html[dir='ltr'] & {
        right: 33px !important;
      }
      html[dir='rtl'] & {
        left: 33px;
        right: auto;
      }
    }
  }
  tr:last-child {
    .tooltiptext {
      bottom: 75px;
      &::after {
        bottom: -5px;
        top: auto;
        transform: rotate(-135deg);
      }
    }
  }
}
