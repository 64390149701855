@import '../../styles/settings';

.dropdown {
  position: relative;
  display: inline-block;
  font-size: 1rem;
  &-language {
    @media only screen and (max-width: 991.98px) {
      .dropdown {
        &__item-selected {
          font-size: 0;
        }
        &__arrow {
          display: none;
        }
        &__img {
          margin: 0;
        }
      }
    }
  }
  &__menu {
    list-style: none;
    margin: 0;
    padding: 5px 13px;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.05);
    border: 1px solid #CFD6DD;
    display: inline-block;
    border-radius: 3px;
    position: absolute;
    bottom: -4px;
    transform: translateY(100%);
    z-index: 9999;
    background: #fff;
    &--right {
      html[dir='ltr'] & {
        right: 0;
      }
      html[dir='rtl'] & {
        left: 0;
      }
    }
    &__item,
    &-item {
      padding: 0.3125rem 0;
      white-space: nowrap;
      .dropdown__item {
        text-transform: inherit;
        &:hover {
          color: $gray-900;
        }
      }
    }
  }
  &__hide {
    display: none;
  }
  &__img {
    width: 24px;
    height: 17px;
    border-radius: 2px;
    object-fit: cover;
    html[dir='ltr'] & {
      margin-right: 12px;
    }
    html[dir='rtl'] & {
      margin-left: 12px;
    }
  }
  &__item {
    text-decoration: none;
    color: $gray-700 !important;
    display: flex;
    align-items: center;
    font-size: 0.875em;
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    html[dir='ltr'] & {
      text-align: left;
    }
    html[dir='rtl'] & {
      text-align: right;
    }
  }
  &--theme-primary {
    color: $primary !important;
  }
  &--theme-secondary {
    color: $gray-900 !important;
  }
  &--theme-white {
    color: $white !important;
  }
  &__arrow {
    font-size: 0.58em;
    html[dir='ltr'] & {
      margin-left: 8px;
    }
    html[dir='rtl'] & {
      margin-right: 8px;
    }
  }
  &Action {
    & > .dropdown__item {
      padding: 0.8125rem 1.125rem;
      border-radius: 2px;
      &:hover {
        background: #e1e1e1;
      }
    }
  }
  &__flex-container {
    width: 100%;
    height: 100%;
    display: flex;
    .dropdown__item {
      width: 100%;
      padding: 0.875rem 0.75rem;
      font-size: 0.875rem;
      line-height: 1rem;
      display: flex;
    }
  }
  &__arrow {
    &--right {
      margin-left: auto;
      html[dir='ltr'] & {
        margin-left: auto;
      }
      html[dir='rtl'] & {
        margin-right: auto;
      }
    }
  }
  &--border {
    border: 1px solid #ccc;
  }
  &--right {
    margin-right: 10px;
  }
}

.user__name {
  color: $primary;
  max-width: 165px;
  min-width: 115px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 12px;
  @media only screen and (max-width: 991.98px) {
    display: none;
  }
}

.labelled-dropdown {
  .label {
    color: #c2bebf;
    font-size: 0.875rem;
  }
  .dropdown__arrow {
    color: #c2bebf;
  }
  .dropdown__item-selected {
    color: $gray-900;
  }
}
