@import '../../assets/sass/custom-variables/variables';

.searchBox {
  font-family: $font-family-sans-serif;
  width: 100%;
  &__select {
    display: flex;
    // min-width: 160px;
    align-items: center;
    padding: 10px 20px;
    // text-transform: uppercase;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    transition: all 0.5s ease-in-out;
    transition: width 0.5s ease-in-out, overflow 0.5s ease-in-out,
      padding 0.5s ease-in-out;
    // flex-basis: 0;
    // flex-grow: 1;
    width: 100%;
    max-width: 100%;
    &--hide {
      @media (max-width: 767px) {
        overflow: hidden;
        width: 0;
        border: 0 !important;
        padding: 0;
      }
    }
  }
  &__select-label {
    font-size: 14px;
    color: $gray-400;
    font-weight: 500;
  }
  &__select-value {
    font-size: 14px;
    color: $gray-900;
    font-weight: 500;
  }
  &__select-icon {
    padding-left: 10px;
    font-size: 8.13px;
    color: $gray-600;
    line-height: 8px;
  }
  &__input {
    flex-basis: 0;
    flex-grow: 1;
    width: 100%;
    max-width: 100%;
    input {
      border: 0;
      padding: 11px 16px;
      height: auto;
      line-height: 22px;
      html[dir='ltr'] & {
        background: linear-gradient(
          90deg,
          rgba(244, 247, 250, 0.75) 0%,
          rgba(244, 247, 250, 0) 100%
        );
      }
      html[dir='rtl'] & {
        background: linear-gradient(
          -90deg,
          rgba(244, 247, 250, 0.75) 0%,
          rgba(244, 247, 250, 0) 100%
        );
      }

      &::-webkit-input-placeholder {
        color: $gray-500;
        font-family: inherit;
      }

      &::-moz-placeholder {
        color: $gray-500;
        font-family: inherit;
      }

      &:-ms-input-placeholder {
        color: $gray-500;
        font-family: inherit;
      }

      &:-moz-placeholder {
        color: $gray-500;
        font-family: inherit;
      }
      &:focus {
        outline: 0;
      }
    }
    .rw-widget {
      @media (min-width: 768px) {
        height: 100%;
      }
    }
    .rw-widget-picker {
      @media (max-width: 767px) {
        height: 45px;
      }
    }
    .rw-widget-picker.rw-widget-input {
      background-color: $white !important;
      border: 0;
      box-shadow: none !important;
      @media (min-width: 768px) {
        height: 100%;
      }
    }
    .rw-dropdown-list-input {
      font-size: 14px;
      font-weight: 500;
      padding-right: 14px !important;
      .rw-placeholder {
        color: $gray-400;
      }
    }
  }
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 13px;
  }
  &__dropdown {
    position: absolute;
    top: 100%;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    background-color: white;
    border: 1px solid #ddd;
    left: 0;
    border-radius: 3px;
    padding: 10px 0;
    width: 100%;
    font-size: 14px;
    margin-top: 8px;
    text-transform: capitalize;
    z-index: 99;
    &-item {
      padding: 10px 13px;
      min-height: 50px;
      &:not(:last-child) {
        border-bottom: 1px solid #ebeff3;
      }
      &:hover {
        background-color: #f9fafc;
      }
    }
    &--hide {
      display: none;
    }
  }
  &__btn {
    background-color: $primary;
    color: $white;
    border: 0;
    font-size: 13px;
    padding: 12px 11px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1;
    font-family: inherit;
    @media (max-width: 767px) {
      background-color: $white;
      color: $primary;
      height: 100%;
      font-size: rem(20);
      padding: 10px;
    }
    &--md-text {
      display: none;
      @media (min-width: 768px) {
        display: block;
      }
    }
    &--sm-text {
      display: none;
      @media (max-width: 767px) {
        display: block;
      }
    }
  }
  &__outer {
    border: 1px solid $border-color;
    background-color: $white;
    box-shadow: 0px 2px 0px 0px #dce6f0;
    @media (max-width: 1023.98px) {
      .save-to-search-link {
        position: absolute;
        top: -51px;
        html[dir='ltr'] & {
          right: 0;
        }
        html[dir='rtl'] & {
          left: 0;
        }
      }
    }
  }
  &__tabs {
    border-bottom: 1px solid #dde3e9;
    @media (max-width: 767px) {
      box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.05);
      border-bottom: 0;
    }
    li {
      span {
        padding: 3px 0;
      }
      &::before {
        content: '';
        width: 100%;
        height: 3px;
        background-color: $primary;
        position: absolute;
        bottom: 0;
        left: 0;
        transform: scaleX(0);
      }
      &.searchBox__tabs--active {
        &::before {
          transform: scaleX(1);
        }
      }
    }
  }
}

.detail-poupup {
  z-index: 1050;
  max-width: 33.33333%;
  top: 0;
  transition: all 0.3s ease-in-out;
  box-shadow: 2px 0px 24px rgba(0, 0, 0, 0.1);
  html[dir='ltr'] & {
    right: -40%;
  }
  html[dir='rtl'] & {
    left: -40%;
  }
  &.active {
    html[dir='ltr'] & {
      right: 0;
    }
    html[dir='rtl'] & {
      left: 0;
    }
  }
  @media (max-width: 1023.98px) {
    max-width: 100%;
    html[dir='ltr'] & {
      right: -100%;
    }
    html[dir='rtl'] & {
      left: -100%;
    }
    &.active {
      html[dir='ltr'] & {
        right: 0;
      }
      html[dir='rtl'] & {
        left: 0;
      }
    }
  }
  &-full {
    max-width: 100% !important;
  }
  &__head {
    border-bottom: 6px solid $body-bg;
  }
  .rw-widget-picker.rw-widget-input {
    background-color: $white !important;
    border: 1px solid #cfd6dd;
    box-shadow: none !important;
    height: 46px;
  }
  .rw-dropdown-list-input {
    font-size: 14px;
    font-weight: 500;
    .rw-placeholder {
      color: $gray-400;
    }
  }
}

.filtered {
  width: 6px;
  height: 6px;
  position: absolute;
  right: -2px;
  top: -2px;
  background-color: $primary;
  border-radius: 50%;
}

.search-suggestions {
  margin-top: -1px;
  max-height: 230px;
  overflow: auto;
  .item {
    &:hover {
      background-color: #f4f7fa;
    }
  }
}
