@import '../../../styles/settings';

.flag__dropdown {
  position: relative;
  display: flex;
  border: 1px solid $input-border-color;
  border-radius: 3px;
}

.flag__dropdown * {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.flag__dropdown__hide {
  display: none;
}

.flag__dropdown input,
.flag__dropdown input[type='text'],
.flag__dropdown input[type='tel'] {
  position: relative;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-right: 0;
}

.flag-container {
  // position: absolute;
  top: 0;
  bottom: 0;
  padding: 1px;

  html[dir='ltr'] & {
    left: 0;
  }

  html[dir='rtl'] & {
    right: 0;
  }
}

.flag__selected-flag {
  // z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  width: 109px;

  html[dir='ltr'] & {
    padding: 0 27px 0 10px;
    border-right: 1px solid $input-border-color;
  }

  html[dir='rtl'] & {
    padding: 0 10px 0 22px;
    border-left: 1px solid $border-color;
  }

  .flag {
    display: inline-block;

    html[dir='ltr'] & {
      margin-right: 5px;
    }

    html[dir='rtl'] & {
      margin-left: 5px;
    }
  }
}
.flag__select-flag {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  width: 60px;

  html[dir='ltr'] & {
    padding: 0 22px 0 10px;
    // border-right: 1px solid $border-color;
  }

  html[dir='rtl'] & {
    padding: 0 10px 0 22px;
    // border-left: 1px solid $border-color;
  }

  .flag {
    display: inline-block;

    html[dir='ltr'] & {
      margin-right: 5px;
    }

    html[dir='rtl'] & {
      margin-left: 5px;
    }
  }
}

.flag__arrow {
  position: absolute;
  top: 50%;
  font-size: 10px;
  transform: translateY(-50%);
  display: inline-flex;

  &:before {
    content: '\e98e';
    font-family: icomoon;
  }

  html[dir='ltr'] & {
    right: 15px;
  }

  html[dir='rtl'] & {
    left: 15px;
  }
}

.country-list {
  position: absolute;
  bottom: 0;
  z-index: 2;
  list-style: none;
  padding: 0;
  margin: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
  background-color: white;
  max-height: 234px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  transform: translateY(100%);
}

.flag__country-list--dropup {
  bottom: 100%;
  margin-bottom: -1px;
}

.country__flag-box {
  display: inline-block;
}

.country {
  padding: 7px 12px;
  outline: none;
  cursor: pointer;
  display: flex;

  &:hover,
  &:focus {
    background-color: rgb(247, 247, 248);
  }

  &__code {
    font-size: 1em;
    font-weight: 500;
  }

  &__divider {
    border-bottom: 1px solid #f3f3f3;
  }
}

.country.country__highlight {
  background-color: rgba($primary, 0.1);
  color: $primary;
}

.country__flag-box,
.country__name,
.country__code {
  vertical-align: middle;
}

.country__flag-box,
.country__name {
  html[dir='ltr'] & {
    margin-right: 10px;
  }

  html[dir='rtl'] & {
    margin-left: 10px;
  }
}

.flag__dropdown input,
.flag__dropdown input[type='text'],
.flag__dropdown input[type='tel'] {
  margin-left: 0;
  border: 0;
  // html[dir='ltr'] & {
  //   padding-left: 110px;
  // }

  // html[dir='rtl'] & {
  //   padding-right: 110px;
  // }
}

.flag__dropdown .flag-container {
  html[dir='ltr'] & {
    right: auto;
    left: 0;
  }

  html[dir='rtl'] & {
    left: auto;
    right: 0;
  }
}

.flag__dropdown .flag-container:hover {
  cursor: pointer;
}

.flag__dropdown input[disabled] + .flag-container:hover,
.flag__dropdown input[readonly] + .flag-container:hover {
  cursor: default;
}

.flag {
  width: 25px;
  height: 25px;
  box-shadow: 0px 0px 1px 0px #888;
  background-repeat: no-repeat;
  background-color: #dbdbdb;
  background-position: 0 0;
  background-image: url('./flag.png');
  transform: scale(0.6);

  &__ad {
    background-position: -5px -0px;
  }

  &__ae {
    background-position: -36px -0px;
  }

  &__af {
    background-position: -67px -0px;
  }

  &__ag {
    background-position: -98px -0px;
  }

  &__ai {
    background-position: -129px -0px;
  }

  &__al {
    background-position: -160px -0px;
  }

  &__am {
    background-position: -191px -0px;
  }

  &__ao {
    background-position: -222px -0px;
  }

  &__ar {
    background-position: -253px -0px;
  }

  &__as {
    background-position: -284px -0px;
  }

  &__at {
    background-position: -315px -0px;
  }

  &__au {
    background-position: -346px -0px;
  }

  &__aw {
    background-position: -377px -0px;
  }

  &__ax {
    background-position: -408px -0px;
  }

  &__az {
    background-position: -439px -0px;
  }

  &__ba {
    background-position: -470px -0px;
  }

  &__bb {
    background-position: -501px -0px;
  }

  &__bd {
    background-position: -5px -31px;
  }

  &__be {
    background-position: -36px -31px;
  }

  &__bf {
    background-position: -67px -31px;
  }

  &__bg {
    background-position: -98px -31px;
  }

  &__bh {
    background-position: -129px -31px;
  }

  &__bi {
    background-position: -160px -31px;
  }

  &__bj {
    background-position: -191px -31px;
  }

  &__bl {
    background-position: -222px -31px;
  }

  &__bm {
    background-position: -253px -31px;
  }

  &__bn {
    background-position: -284px -31px;
  }

  &__bo {
    background-position: -315px -31px;
  }

  &__bq {
    background-position: -346px -31px;
  }

  &__br {
    background-position: -377px -31px;
  }

  &__bs {
    background-position: -408px -31px;
  }

  &__bt {
    background-position: -439px -31px;
  }

  &__bw {
    background-position: -470px -31px;
  }

  &__by {
    background-position: -501px -31px;
  }

  &__bz {
    background-position: -5px -62px;
  }

  &__ca {
    background-position: -36px -62px;
  }

  &__cc {
    background-position: -67px -62px;
  }

  &__cd {
    background-position: -98px -62px;
  }

  &__cf {
    background-position: -129px -62px;
  }

  &__cg {
    background-position: -160px -62px;
  }

  &__ch {
    background-position: -191px -62px;
  }

  &__ci {
    background-position: -222px -62px;
  }

  &__ck {
    background-position: -253px -62px;
  }

  &__cl {
    background-position: -284px -62px;
  }

  &__cm {
    background-position: -315px -62px;
  }

  &__cn {
    background-position: -346px -62px;
  }

  &__co {
    background-position: -377px -62px;
  }

  &__cr {
    background-position: -408px -62px;
  }

  &__cu {
    background-position: -439px -62px;
  }

  &__cv {
    background-position: -470px -62px;
  }

  &__cw {
    background-position: -501px -62px;
  }

  &__cx {
    background-position: -5px -93px;
  }

  &__cy {
    background-position: -36px -93px;
  }

  &__cz {
    background-position: -67px -93px;
  }

  &__de {
    background-position: -98px -93px;
  }

  &__dj {
    background-position: -129px -93px;
  }

  &__dk {
    background-position: -160px -93px;
  }

  &__dm {
    background-position: -191px -93px;
  }

  &__do {
    background-position: -222px -93px;
  }

  &__dz {
    background-position: -253px -93px;
  }

  &__ec {
    background-position: -284px -93px;
  }

  &__ee {
    background-position: -315px -93px;
  }

  &__eg {
    background-position: -346px -93px;
  }

  &__eh {
    background-position: -377px -93px;
  }

  &__er {
    background-position: -408px -93px;
  }

  &__es {
    background-position: -439px -93px;
  }

  &__et {
    background-position: -470px -93px;
  }

  &__fi {
    background-position: -501px -93px;
  }

  &__fj {
    background-position: -5px -124px;
  }

  &__fk {
    background-position: -36px -124px;
  }

  &__fm {
    background-position: -67px -124px;
  }

  &__fo {
    background-position: -98px -124px;
  }

  &__fr {
    background-position: -129px -124px;
  }

  &__ga {
    background-position: -160px -124px;
  }

  &__gb {
    background-position: -191px -124px;
  }

  &__gd {
    background-position: -222px -124px;
  }

  &__ge {
    background-position: -253px -124px;
  }

  &__gf {
    background-position: -284px -124px;
  }

  &__gg {
    background-position: -315px -124px;
  }

  &__gh {
    background-position: -346px -124px;
  }

  &__gi {
    background-position: -377px -124px;
  }

  &__gl {
    background-position: -408px -124px;
  }

  &__gm {
    background-position: -439px -124px;
  }

  &__gn {
    background-position: -470px -124px;
  }

  &__gp {
    background-position: -501px -124px;
  }

  &__gq {
    background-position: -5px -155px;
  }

  &__gr {
    background-position: -36px -155px;
  }

  &__gt {
    background-position: -67px -155px;
  }

  &__gu {
    background-position: -98px -155px;
  }

  &__gw {
    background-position: -129px -155px;
  }

  &__gy {
    background-position: -160px -155px;
  }

  &__hk {
    background-position: -191px -155px;
  }

  &__hn {
    background-position: -222px -155px;
  }

  &__hr {
    background-position: -253px -155px;
  }

  &__ht {
    background-position: -284px -155px;
  }

  &__hu {
    background-position: -315px -155px;
  }

  &__id {
    background-position: -346px -155px;
  }

  &__ie {
    background-position: -377px -155px;
  }

  &__il {
    background-position: -408px -155px;
  }

  &__im {
    background-position: -439px -155px;
  }

  &__in {
    background-position: -470px -155px;
  }

  &__io {
    background-position: -501px -155px;
  }

  &__iq {
    background-position: -5px -186px;
  }

  &__ir {
    background-position: -36px -186px;
  }

  &__is {
    background-position: -67px -186px;
  }

  &__it {
    background-position: -98px -186px;
  }

  &__je {
    background-position: -129px -186px;
  }

  &__jm {
    background-position: -160px -186px;
  }

  &__jo {
    background-position: -191px -186px;
  }

  &__jp {
    background-position: -222px -186px;
  }

  &__ke {
    background-position: -253px -186px;
  }

  &__kg {
    background-position: -284px -186px;
  }

  &__kh {
    background-position: -315px -186px;
  }

  &__ki {
    background-position: -346px -186px;
  }

  &__km {
    background-position: -377px -186px;
  }

  &__kn {
    background-position: -408px -186px;
  }

  &__kp {
    background-position: -439px -186px;
  }

  &__kr {
    background-position: -470px -186px;
  }

  &__kw {
    background-position: -501px -186px;
  }

  &__ky {
    background-position: -5px -217px;
  }

  &__kz {
    background-position: -36px -217px;
  }

  &__la {
    background-position: -67px -217px;
  }

  &__lb {
    background-position: -98px -217px;
  }

  &__lc {
    background-position: -129px -217px;
  }

  &__li {
    background-position: -160px -217px;
  }

  &__lk {
    background-position: -191px -217px;
  }

  &__lr {
    background-position: -222px -217px;
  }

  &__ls {
    background-position: -253px -217px;
  }

  &__lt {
    background-position: -284px -217px;
  }

  &__lu {
    background-position: -315px -217px;
  }

  &__lv {
    background-position: -346px -217px;
  }

  &__ly {
    background-position: -377px -217px;
  }

  &__ma {
    background-position: -408px -217px;
  }

  &__mc {
    background-position: -439px -217px;
  }

  &__md {
    background-position: -470px -217px;
  }

  &__me {
    background-position: -501px -217px;
  }

  &__mf {
    background-position: -5px -248px;
  }

  &__mg {
    background-position: -36px -248px;
  }

  &__mh {
    background-position: -67px -248px;
  }

  &__mk {
    background-position: -98px -248px;
  }

  &__ml {
    background-position: -129px -248px;
  }

  &__mm {
    background-position: -160px -248px;
  }

  &__mn {
    background-position: -191px -248px;
  }

  &__mo {
    background-position: -222px -248px;
  }

  &__mp {
    background-position: -253px -248px;
  }

  &__mq {
    background-position: -284px -248px;
  }

  &__mr {
    background-position: -315px -248px;
  }

  &__ms {
    background-position: -346px -248px;
  }

  &__mt {
    background-position: -377px -248px;
  }

  &__mu {
    background-position: -408px -248px;
  }

  &__mv {
    background-position: -439px -248px;
  }

  &__mw {
    background-position: -470px -248px;
  }

  &__mx {
    background-position: -501px -248px;
  }

  &__my {
    background-position: -5px -279px;
  }

  &__mz {
    background-position: -36px -279px;
  }

  &__na {
    background-position: -67px -279px;
  }

  &__nc {
    background-position: -98px -279px;
  }

  &__ne {
    background-position: -129px -279px;
  }

  &__nf {
    background-position: -160px -279px;
  }

  &__ng {
    background-position: -191px -279px;
  }

  &__ni {
    background-position: -222px -279px;
  }

  &__nl {
    background-position: -253px -279px;
  }

  &__no {
    background-position: -284px -279px;
  }

  &__np {
    background-position: -315px -279px;
  }

  &__nr {
    background-position: -346px -279px;
  }

  &__nu {
    background-position: -377px -279px;
  }

  &__nz {
    background-position: -408px -279px;
  }

  &__om {
    background-position: -439px -279px;
  }

  &__pa {
    background-position: -470px -279px;
  }

  &__pe {
    background-position: -501px -279px;
  }

  &__pf {
    background-position: -5px -310px;
  }

  &__pg {
    background-position: -36px -310px;
  }

  &__ph {
    background-position: -67px -310px;
  }

  &__pk {
    background-position: -98px -310px;
  }

  &__pl {
    background-position: -129px -310px;
  }

  &__pm {
    background-position: -160px -310px;
  }

  &__pn {
    background-position: -191px -310px;
  }

  &__pr {
    background-position: -222px -310px;
  }

  &__ps {
    background-position: -253px -310px;
  }

  &__pt {
    background-position: -284px -310px;
  }

  &__pw {
    background-position: -315px -310px;
  }

  &__py {
    background-position: -346px -310px;
  }

  &__qa {
    background-position: -377px -310px;
  }

  &__re {
    background-position: -408px -310px;
  }

  &__ro {
    background-position: -439px -310px;
  }

  &__rs {
    background-position: -470px -310px;
  }

  &__ru {
    background-position: -501px -310px;
  }

  &__rw {
    background-position: -5px -341px;
  }

  &__sa {
    background-position: -36px -341px;
  }

  &__sb {
    background-position: -67px -341px;
  }

  &__sc {
    background-position: -98px -341px;
  }

  &__sd {
    background-position: -129px -341px;
  }

  &__se {
    background-position: -160px -341px;
  }

  &__sg {
    background-position: -191px -341px;
  }

  &__sh {
    background-position: -222px -341px;
  }

  &__si {
    background-position: -253px -341px;
  }

  &__sj {
    background-position: -284px -341px;
  }

  &__sk {
    background-position: -315px -341px;
  }

  &__sl {
    background-position: -346px -341px;
  }

  &__sm {
    background-position: -377px -341px;
  }

  &__sn {
    background-position: -408px -341px;
  }

  &__so {
    background-position: -439px -341px;
  }

  &__sr {
    background-position: -470px -341px;
  }

  &__ss {
    background-position: -501px -341px;
  }

  &__st {
    background-position: -5px -372px;
  }

  &__sv {
    background-position: -36px -372px;
  }

  &__sx {
    background-position: -67px -372px;
  }

  &__sy {
    background-position: -98px -372px;
  }

  &__sz {
    background-position: -129px -372px;
  }

  &__tc {
    background-position: -160px -372px;
  }

  &__td {
    background-position: -191px -372px;
  }

  &__tg {
    background-position: -222px -372px;
  }

  &__th {
    background-position: -253px -372px;
  }

  &__tj {
    background-position: -284px -372px;
  }

  &__tk {
    background-position: -315px -372px;
  }

  &__tl {
    background-position: -346px -372px;
  }

  &__tm {
    background-position: -377px -372px;
  }

  &__tn {
    background-position: -408px -372px;
  }

  &__to {
    background-position: -439px -372px;
  }

  &__tr {
    background-position: -470px -372px;
  }

  &__tt {
    background-position: -501px -372px;
  }

  &__tv {
    background-position: -5px -403px;
  }

  &__tw {
    background-position: -36px -403px;
  }

  &__tz {
    background-position: -67px -403px;
  }

  &__ua {
    background-position: -98px -403px;
  }

  &__ug {
    background-position: -129px -403px;
  }

  &__um {
    background-position: -160px -403px;
  }

  &__us {
    background-position: -191px -403px;
  }

  &__uy {
    background-position: -222px -403px;
  }

  &__uz {
    background-position: -253px -403px;
  }

  &__va {
    background-position: -284px -403px;
  }

  &__vc {
    background-position: -315px -403px;
  }

  &__ve {
    background-position: -346px -403px;
  }

  &__vg {
    background-position: -377px -403px;
  }

  &__vi {
    background-position: -408px -403px;
  }

  &__vn {
    background-position: -439px -403px;
  }

  &__vu {
    background-position: -470px -403px;
  }

  &__wf {
    background-position: -501px -403px;
  }

  &__ws {
    background-position: -5px -434px;
  }

  &__xk {
    background-position: -36px -434px;
  }

  &__ye {
    background-position: -67px -434px;
  }

  &__yt {
    background-position: -98px -434px;
  }

  &__za {
    background-position: -129px -434px;
  }

  &__zm {
    background-position: -160px -434px;
  }

  &__zw {
    background-position: -191px -434px;
  }
}
