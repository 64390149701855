
.notification-toast{
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 1000;
    width: 70%;
    height: auto;
    padding: 1rem;
    background-color: #ffffff;
    border-radius: 7px;
    display: grid;
    grid-template-columns: 1.3fr 6fr 0.5fr;
    box-shadow: 0 15px 30px rgba(0,0,0,0.08);
}
.success{
    border-left: 3px solid #47D764;
}
.error{
    border-left: 3px solid #ff355b;
}
.info{
    border-left: 3px solid #2F86EB;
}
.warning{
    border-left: 3px solid #FFC021;
}
.error i{
    color: #ff355b;
}
.info i{
    color: #2F86EB;
}
.warning i{
    color: #FFC021;
}
.notification-toast:not(:last-child){
    margin-bottom: 20px;
}
.outer-container,.inner-container{
    align-self: center;
} 
.outer-container i{
    font-size: 35px;
}
.success i{ 
    color: #47D764;
}
.inner-container p:first-child{
    color: #101020;
    font-weight: 600;
    font-size: 16px;
}
.inner-container p:last-child{
    font-size: 12px;
    font-weight: 400;
    color: #656565;
}
.notification-toast .close-button{
    align-self: flex-start;
    background-color: transparent;
    font-size: 1.8em;
    color: #656565;
    line-height: 0;
    cursor: pointer;
    margin-right:-30px;
}