@import 'custom-variables/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.font-xxxxs {
  font-size: 8px;
}

.form-control-search {
  // background-image: url(./images/search-icon.png);
  background-repeat: no-repeat;
  background-position: center center;
  font-size: 0;
}

.filter-search {
  min-width: 260px;

  @include media-breakpoint-down(md) {
    border: 1px solid $input-border-color;
    border-radius: $input-border-radius;
  }
  .form-control::placeholder {
    color: $input-placeholder-color;
  }
}

.dropdown-shadow {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.18);
}

.dropdown-menu {
  transform: scale(0.95);
  transform-origin: top center;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  visibility: hidden;
  display: block;
  min-width: 278px;
  z-index: 999;

  html[dir='rtl'] & {
    left: inherit;
    right: 0;
    text-align: right;
  }

  &.in {
    transform: scale(1);
    opacity: 1;
    visibility: visible;
  }
}

.filter-selected {
  background-color: rgba($primary, 0.08);
}

.dropdown-save {
  .dropdown-menu {
    html[dir='ltr'] & {
      left: inherit;
      right: 0;
    }
  }
}

.dropdown-add-filter {
  .dropdown-menu {
    max-height: 375px;
    overflow: auto;
    min-width: 301px !important;

    @include media-breakpoint-down(md) {
      html[dir='ltr'] & {
        left: -75px;
      }

      html[dir='rtl'] & {
        right: -75px;
        left: inherit;
      }
    }

    &.subIn {
      overflow: hidden;
    }

    .sub-dropdown {
      height: 100%;
      overflow: auto;
      display: none;

      &.in {
        display: block;
      }

      ul {
        max-height: 209px;
        overflow: auto;
      }
    }
  }
}

.view-switch {
  span {
    color: lighten($dark, 76.86);
    padding: 12px 14px;

    &.active {
      color: $primary;
    }

    & + span {
      html[dir='ltr'] & {
        border-left: 1px solid $border-color;
      }

      html[dir='rtl'] & {
        border-right: 1px solid $border-color;
      }
    }
  }
}

.grid-wrapper {
  .grid-box {
    min-width: rem(310);
    max-width: rem(310);

    & + .grid-box {
      html[dir='ltr'] & {
        border-left: 1px solid $border-color;
      }

      html[dir='rtl'] & {
        border-right: 1px solid $border-color;
      }
    }
  }

  .grid-head {
    .grid-head-item {
      min-height: rem(70);
      background-color: darken($white, 4.31);

      & + .grid-head-item {
        &:before {
          content: '\e921';
          font-family: icomoon;
          position: absolute;
          top: 50%;
          margin-top: -13px;
          width: 26px;
          height: 26px;
          border: 1px solid $border-color;
          border-radius: 50%;
          background-color: $white;
          color: lighten($dark, 76.86);
          font-size: 11px;
          display: inline-flex;
          align-items: center;
          justify-content: center;

          html[dir='ltr'] & {
            left: -13px;
          }

          html[dir='rtl'] & {
            right: -13px;
          }
        }
      }
    }
  }

  .grid-view {
    .grid-view-item {
      height: 93px;
      .grid-view-content {
        max-width: calc(100% - 30px);
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .overflow-lg-auto {
    overflow: auto;
  }
}

.icon-caret- {
  &down,
  &up {
    font-size: 4px;
  }
}

.btn-group {
  .active {
    background-color: $primary;
    color: $white;
    font-weight: 600;
  }
}

.btn-group {
  .disabled {
    background-color: #a5a2a2 !important;
    color: $white;
    font-weight: 600;
  }
}

.popover {
  background-color: $white;
  border: 1px solid $border-color;
  z-index: 9999;
}

.calendar-info-popover {
  min-width: 400px;
  max-width: 400px;
}

textarea.textarea-sm {
  height: 87px;
}

.time-box {
  width: 52px;
  min-width: 52px;
  height: 23px;
}

.blue-dot {
  background: #38a3ff;
  width: 8px;
  min-width: 8px;
  height: 8px;
}

.calendar-activity-list {
  max-height: 300px;
}

.calendar-sm {
  .rdrCalendarWrapper {
    width: 100%;
    .rdrMonth {
      width: 100%;
    }
  }
  .rdrDayStartPreview,
  .rdrDayEndPreview,
  .rdrSelected {
    width: 32px;
    height: 32px;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 50%;
    color: #2848ae !important;
  }
  .rdrCalendarWrapper:not(.rdrDateRangeWrapper)
    .rdrDayHovered
    .rdrDayNumber:after {
    display: none;
  }
}

.rdrDayToday .rdrDayNumber span:after {
  display: none;
}

.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
  color: #2848ae !important;
}

.greyButton {
  background-color: #dcdcdc;
  border-radius: 8px;
  padding: 8px;
}

.grid-view-icon-round {
  width: 18px;
  height: 18px;
  &::before {
    content: attr(data-toggle);
    width: auto;
    background-color: $white;
    font-size: 11px;
    color: $gray-800;
    border: 1px solid $gray-500;
    border-radius: 4px;
    position: absolute;
    top: 100%;
    white-space: nowrap;
    padding: 2px 5px;
    visibility: hidden;
    opacity: 0;
  }
  &::after {
    content: '';
    width: 6px;
    height: 6px;
    border-left: 1px solid $gray-500;
    border-top: 1px solid $gray-500;
    display: inline-block;
    position: absolute;
    top: 100%;
    transform: rotate(45deg);
    margin-top: -3px;
    background: $white;
    visibility: hidden;
    opacity: 0;
  }
  &:hover {
    &::before,
    &::after {
      visibility: visible;
      opacity: 1;
    }
  }
}

.draggable-list {
  z-index: 1;
  list-style: none;
}
.draggable-list .icon-drag-indicator {
  cursor: move;
}

.filter-choice {
  & > div {
    border-radius: 50em !important;
  }
}
