@import '../../../styles/settings';

.textField {
  position: relative;
  margin-bottom: 1rem;
  font-size: 0.875rem;

  &__input {
    display: block;
    width: 100%;
    padding: 14px;
    font-size: 14px;
    color: $gray-900;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid $input-border-color;
    outline: none;
    font-family: inherit;

    &::-webkit-input-placeholder {
      color: $gray-400;
      font-family: inherit;
    }

    &::-moz-placeholder {
      color: $gray-400;
      font-family: inherit;
    }

    &:-ms-input-placeholder {
      color: $gray-400;
      font-family: inherit;
    }

    &:-moz-placeholder {
      color: $gray-400;
      font-family: inherit;
    }

    &:focus {
      outline: none;
    }
  }

  &__validation {
    color: #ef2c2c;
    font-size: 12px;
  }
}
