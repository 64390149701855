@import '../../styles/settings';

.numberField {
  display: inline-block;
  position: relative;

  &-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &__inner {
    display: flex;
    line-height: 31px;
    max-width: 106px;
    background-color: $white;
    border: 1px solid #abc2da;
    border-radius: 6px;
    overflow: hidden;
  }

  &__input {
    width: 59px;
    text-align: center;
    font-family: inherit;
    font-weight: 500;
    border: none;
    font-size: 13px;
    padding: 0 3px;

    &:focus {
      outline: none;
    }
  }

  &__plus,
  &__minus {
    width: 22px;
    text-align: center;
    cursor: pointer;
    position: relative;
    background-color: #f4f7fa;

    &:before,
    &:after {
      content: '';
      width: 10px;
      height: 2px;
      background-color: #3a3b46;
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__plus {
    &:before,
    &:after {
      background-color: #3a3b46;
    }
    &:after {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }

  &__minus:after {
    display: none;
  }

  &__label {
    color: $gray-300;
    text-align: center;
    text-transform: uppercase;
    font-size: 11px;
    margin-top: 5px;
  }

  &--readonly .numberField__inner {
    opacity: 0.75;
    pointer-events: none;
  }

  &--sm {
    .numberField {
      &__input {
        font-size: 14px;
        font-weight: 400;
      }

      &__plus {
        font-size: 18px;
      }

      &__minus {
        font-size: 33px;
      }

      &__label {
        font-size: 10px;
      }
    }
  }

  &--primary {
    .numberField {
      &__inner {
        box-shadow: none;
      }

      &__input {
        border: 0;
        color: $primary;
      }

      &__plus,
      &__minus {
        color: $primary;
        border-color: $primary;
        background-color: $white;
      }
    }
  }
  &__loading {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.4);
  }
}
