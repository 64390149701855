@import '../../../assets/sass/custom-variables/variables';

.apply-promo-cart {
  border: 1px dashed rgba($primary, 0.3);
  background-color: rgba($primary, 0.05);
  padding: 13px;

  input {
    border-radius: 6px;
    border: 1px solid rgba($primary, 0.3);
  }
}

.price-block {
  top: 110px;
}

.cart-table {
  line-height: 1.4;
  @media (max-width: 1023.98px) {
    thead {
      display: none;
    }

    tr {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    td {
      max-width: 60%;
      &:last-child {
        width: 100%;
        padding: 0 16px 14px 16px;
      }
    }
  }
  th {
    border-bottom: 3px solid $border-color;
    padding: 14px 16px;
  }
  td {
    font-weight: 500;
    @media (min-width: 1024px) {
      border-top: 1px solid $border-color;
      padding: 9px 16px;
    }
    @media (max-width: 1023.98px) {
      padding: 12px 16px;
    }
  }
  tr {
    &:first-child {
      border-top: none;
      td {
        border-top: none;
      }
    }
    @media (min-width: 1024px) {
      &:hover {
        td {
          background-color: $body-bg;
        }
        .option {
          opacity: 1;
          visibility: visible;
          color: $body-bg;
        }
      }
      .option {
        color: $white;
        transition: all 0.4s ease-in-out;
        opacity: 0;
        visibility: hidden;
        &:hover {
          color: $white;
        }
      }
    }

    @media (max-width: 1023.98px) {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid $border-color;
      .check {
        position: absolute;
        z-index: 10;
      }
      .option {
        color: $primary;
      }
    }
  }

  .numberField__inner {
    line-height: 29px;
  }
}
