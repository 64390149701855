@import '../../styles/settings';

.Button {
  display: inline-flex;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  font-family: inherit;
  cursor: pointer;
  // text-transform: uppercase;
  font-size: 14px;
  line-height: normal;
  padding: rem(14) rem(45);
  background: $white;
  border: 1px solid $white;
  color: $gray-300;

  &:hover,
  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  &--xs {
    font-size: 12px;
    padding: 9px 20px;
  }
  &--xl {
    padding: rem(18) 1.5rem;
    font-size: 14px;
  }
  &--xlg {
    padding: 0.8rem 3.4375rem;
  }
  &--md {
    font-size: 16px;
    padding: 11px 20px;
  }
  &--sm {
    font-size: 14px;
    padding: 8px 18px;
  }
  &--lg {
    padding: rem(15) rem(55);
  }

  &--block {
    width: 100%;
  }

  &--Capitalize {
    text-transform: capitalize;
  }

  &--font-light {
    font-weight: 300;
  }
  &--radius {
    border-radius: 6px;
  }
  &__primary {
    border-color: $primary;
    background-color: $primary;
    color: $white;

    &:hover {
      border-color: darken($primary, 7.45);
      background-color: darken($primary, 7.45);
    }
  }

  &__primary-outline {
    color: $primary;
    background-color: $white;
    border-color: $primary;

    &:hover {
      color: $white;
      background-color: $primary;
    }
  }

  &__secondary {
    border-color: $secondary;
    background-color: $secondary;
    color: $white;

    &:hover {
      border-color: darken($secondary, 7.45);
      background-color: darken($secondary, 7.45);
    }
  }

  &__secondary-outline {
    color: $secondary;
    background-color: $white;
    border-color: $secondary;

    &:hover {
      color: $white;
      background-color: $secondary;
    }
  }
  &__teritary-outline {
    color: $white;
    border-color: $white;
    background-color: transparent;
    &:hover {
      color: $white;
      background-color: $primary;
    }
  }

  &__light {
    background-color: $white;
    border-color: $white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);

    &:hover {
      color: $primary;
    }
  }

  &__light--outline {
    background-color: transparent;
    border-color: $white;
    color: $white;
  }

  .icon {
    &--left {
      html[dir='ltr'] & {
        margin-right: 7px;
      }

      html[dir='rtl'] & {
        margin-left: 7px;
      }
    }

    &--right {
      html[dir='ltr'] & {
        margin-left: 7px;
      }

      html[dir='rtl'] & {
        margin-right: 7px;
      }
    }
  }
}

.ButtonBlock {
  &--gap-sm {
    padding: 1rem 0;
  }
  &--gap-lg {
    padding: 2rem 0;
  }
  &--left {
    text-align: left;
  }
  &--center {
    text-align: center;
  }
  &--right {
    text-align: right;
  }
}
