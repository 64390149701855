@import '../../assets/sass/custom-variables/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.form-control-search {
  background-image: url(../../assets/images/search-icon.png);
  background-repeat: no-repeat;
  background-position: center center;
  font-size: 0 !important;
}
.form-control-date {
  background-image: url(../../assets/images/calender.png);
  background-repeat: no-repeat;
  background-position: center center;
  font-size: 0 !important;
}

.filter-search {
  min-width: 190px;

  @include media-breakpoint-down(md) {
    border: 1px solid $input-border-color;
    border-radius: $input-border-radius;
  }
  .form-control::placeholder {
    color: $input-placeholder-color;
  }
}
.filter-date {
  min-width: 207px;
  max-width: 207px;
  @include media-breakpoint-down(md) {
    // border: 1px solid $border-color;
    max-width: 100%;
  }
  .form-control::placeholder {
    color: #808080;
  }
}

input {
  &:focus {
    // border: none !important;
    box-shadow: none !important;
  }
}
.dropdown-shadow {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.18);
}
.filter-head {
  background: #f4f7fa;
}
.dropdown-menu {
  transform: scale(0.95);
  transform-origin: top center;
  transition: all 0.2s ease-in-out;
  // opacity: 0;
  // visibility: hidden;
  display: block !important;
  min-width: 241px !important;
  z-index: 999;

  &.in {
    transform: scale(1);
    opacity: 1;
    visibility: visible;
  }
  &.sm {
    min-width: 230px !important;
  }
}

.dropdown-action {
  .dropdown-menu {
    min-width: auto;
  }
}

.dropdown-save {
  .dropdown-menu {
    html[dir='ltr'] & {
      left: inherit;
      right: 0;
    }
  }
}
