@import 'custom-variables/variables';

/* CUSTOM CHECKBOX */

.custom-checkbox {
  position: relative;
  font-size: 0.8125rem;

  .custom__label {
    font-size: 0.8125rem;
    line-height: 16px;
  }

  [type='checkbox']:not(:checked),
  [type='checkbox']:checked {
    position: absolute;
    left: -9999px;
    display: none;
  }

  [type='checkbox']:not(:checked) + label {
    color: $gray-300;
  }

  [type='checkbox']:checked + label {
    color: $gray-900;
  }

  [type='checkbox']:not(:checked) + label,
  [type='checkbox']:checked + label {
    position: relative;
    cursor: pointer;
    margin: 0;

    html[dir='ltr'] & {
      padding-left: 28px;
    }

    html[dir='rtl'] & {
      padding-right: 28px;
    }
  }

  [type='checkbox']:not(:checked) + label:before,
  [type='checkbox']:checked + label:before {
    content: '';
    position: absolute;
    top: 0px;
    width: 16px;
    height: 16px;
    border: 1px solid $border-color;
    background: #fff;

    html[dir='ltr'] & {
      left: 0;
    }

    html[dir='rtl'] & {
      right: 0;
    }
  }

  [type='checkbox']:not(:checked) + label:after,
  [type='checkbox']:checked + label:after {
    position: absolute;
    content: '';
    top: 2px;
    width: 6px;
    height: 10px;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    transition: all 0.2s;
    transform: rotate(35deg);
    border-radius: 0 0 2px 0;

    html[dir='ltr'] & {
      left: 5px;
    }

    html[dir='rtl'] & {
      right: 5px;
    }
  }

  [type='checkbox']:not(:checked) + label:after {
    opacity: 0;
  }

  [type='checkbox']:checked + label:before {
    border-color: $primary;
    background-color: $primary;
  }

  [type='checkbox']:checked + label:after {
    opacity: 1;
  }
}

/*CUSTOM CHECKBOX ROUND */
.custom-checkbox-round {
  position: relative;
  font-size: 0.8125rem;

  .custom__label {
    font-size: 0.8125rem;
    line-height: 16px;
  }

  [type='checkbox']:not(:checked),
  [type='checkbox']:checked {
    position: absolute;
    left: -9999px;
    display: none;
  }

  [type='checkbox']:not(:checked) + label {
    color: $gray-300;
  }

  [type='checkbox']:checked + label {
    color: $gray-900;
  }

  [type='checkbox']:not(:checked) + label,
  [type='checkbox']:checked + label {
    position: relative;
    cursor: pointer;
    margin: 0;

    html[dir='ltr'] & {
      padding-left: 28px;
      display: block !important;
    }

    html[dir='rtl'] & {
      padding-right: 28px;
    }
  }

  [type='checkbox']:not(:checked) + label:before,
  [type='checkbox']:checked + label:before {
    content: '';
    position: absolute;
    top: -1px;
    width: 20px;
    height: 20px;
    border: 1px solid $border-color;
    border-radius: 50%;
    background: #fff;

    html[dir='ltr'] & {
      left: 0;
    }

    html[dir='rtl'] & {
      right: 0;
    }
  }

  [type='checkbox']:not(:checked) + label:after,
  [type='checkbox']:checked + label:after {
    position: absolute;
    content: '';
    top: 2px;
    width: 6px;
    height: 10px;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    transition: all 0.2s;
    transform: rotate(35deg);
    border-radius: 0 0 2px 0;

    html[dir='ltr'] & {
      left: 7px;
    }

    html[dir='rtl'] & {
      right: 5px;
    }
  }

  [type='checkbox']:not(:checked) + label:after {
    opacity: 0;
  }

  [type='checkbox']:checked + label:before {
    border-color: $primary;
    background-color: $primary;
  }

  [type='checkbox']:checked + label:after {
    opacity: 1;
  }
}

/* CUSTOM RADIO BUTTON */

.custom-radio {
  display: inline-block;

  input[type='radio'] {
    display: none;
  }
  &--mright {
    margin-right: 3rem;
    @media (max-width: 575px) {
      margin-right: 1rem;
    }
  }
  &--uppercase {
    text-transform: uppercase;
  }
  &--fontweight {
    font-weight: 600;
  }
  input[type='radio'] + label {
    position: relative;
    cursor: pointer;
    font-size: 0.875rem;
    color: #000;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 20px;

    html[dir='ltr'] & {
      padding-left: 32px;
    }

    html[dir='rtl'] & {
      padding-right: 32px;
    }

    &:before {
      content: '';
      position: absolute;
    }

    &:before {
      width: 20px;
      height: 20px;
      border: 2px solid #bababa;
      border-radius: 50%;
      background: #fff;
      top: 0;
      display: block;
      transition: all ease 0.3s;
      background-clip: content-box;
      padding: 2px;

      html[dir='ltr'] & {
        left: 0;
      }

      html[dir='rtl'] & {
        right: 0;
      }
    }
  }

  input[type='radio']:checked + label {
    &:before {
      border-color: #f47a20;
      background-color: #f47a20;
    }
  }
}

// CHECKBOX SWITCH

.switch {
  position: relative;
  display: inline-flex;
  align-items: center;
  input {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
    &:checked + .slider {
      // background-color: #2196F3;
    }
    &:focus + .slider {
      // box-shadow: 0 0 1px #2196F3;
    }
    &:checked + .on {
      color: #282828;
    }
    &:checked + .on + .slider + .off {
      color: #c7c7c7;
    }
    &:checked + .label + .slider:before {
      -webkit-transform: translateX(0px);
      -ms-transform: translateX(0px);
      transform: translateX(0px);
      background-color: #24c428;
    }
  }
  .slider {
    position: relative;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid #cfcdcd;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    width: 46px;
    height: 16px;
    display: inline-block;
    box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.15);
    &:before {
      position: absolute;
      content: '';
      height: 12px;
      width: 22px;
      left: 1px;
      bottom: 1px;
      background-color: #d4d2d2;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      -webkit-transform: translateX(20px);
      -ms-transform: translateX(20px);
      transform: translateX(20px);
    }
  }
  .slider.round {
    border-radius: 34px;
    &:before {
      border-radius: 34px;
    }
  }
  .label {
    font-size: 12px;
    color: #c7c7c7;
    &.off {
      color: #282828;
      margin-left: 4px;
    }
    &.on {
      margin-right: 4px;
    }
  }
}
