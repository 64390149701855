@import '/../../styles/settings';

.Loader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;

  &__text {
    color: $gray-900;
    margin-top: rem(10);
    font-size: 14px;
    font-weight: 500;
  }
}

.ContainerLoader {
  &__icon {
    img {
      width: 50px;
      border-radius: 50%;
    }
  }
}

.FullLoader {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(#000, 0.6);
}

.loader__icon {
  position: relative;
  width: 65px;
  height: 65px;
  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 100;
  }

  .circle {
    position: relative;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    background-image: conic-gradient(#dce6f0 75%, $primary 95%);
    animation: spin 1s infinite linear backwards;
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 55px;
      height: 55px;
      background: $white;
      border-radius: 50%;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
