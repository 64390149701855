@import '../../styles/settings';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.footer {
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.05);
  background: $white;
  z-index: 101;
}

.border-gray-left {
  html[dir='ltr'] & {
    border-left: 1px solid #808080;
  }

  html[dir='rtl'] & {
    border-right: 1px solid #808080;
  }
}
