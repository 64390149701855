@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?exx2pa');
  src: url('../fonts/icomoon.eot?exx2pa#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?exx2pa') format('truetype'),
    url('../fonts/icomoon.woff?exx2pa') format('woff'),
    url('../fonts/icomoon.svg?exx2pa#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bullet1:before {
  content: '\2022';
}
.icon-bullet:before {
  content: '\25cf';
}
.icon-graph-view:before {
  content: '\e945';
  color: #808080;
}

.icon-table-view:before {
  content: '\e946';
  color: #808080;
}

.icon-reports:before {
  content: '\e944';
}

.icon-lock:before {
  content: '\e941';
}

.icon-credit-request:before {
  content: '\e942';
}

.icon-creditline:before {
  content: '\e93d';
}

.icon-payment-history:before {
  content: '\e93e';
}

.icon-terms:before {
  content: '\e940';
}

.icon-download-outline:before {
  content: '\e93b';
}

.icon-footer-home:before {
  content: '\e92d';
}

.icon-footer-cart:before {
  content: '\e92e';
}

.icon-footer-quotes:before {
  content: '\e92f';
}

.icon-footer-search:before {
  content: '\e930';
}

.icon-footer-account:before {
  content: '\e932';
}

.icon-branch-info:before {
  content: '\e92c';
}

.icon-info:before {
  content: '\e92b';
}

.icon-caret-up:before {
  content: '\e9a0';
}

.icon-caret-down:before {
  content: '\e9a1';
}

.icon-drag:before {
  content: '\e99f';
  color: #808080;
}

.icon-confirm:before {
  content: '\e99e';
}

.icon-menu:before {
  content: '\e99c';
}

.icon-close-main:before {
  content: '\e99d';
}

.icon-order-request:before {
  content: '\e991';
}

.icon-quotation:before {
  content: '\e992';
}

.icon-my_orders:before {
  content: '\e993';
}

.icon-catalog:before {
  content: '\e994';
}

.icon-offers:before {
  content: '\e995';
  color: #ffffff;
}

.icon-bulk:before {
  content: '\e996';
}

.icon-logout:before {
  content: '\e997';
}

.icon-faq:before {
  content: '\e998';
}

.icon-account-settings:before {
  content: '\e999';
}

.icon-payment-center:before {
  content: '\e99a';
}

.icon-return:before {
  content: '\e99b';
}

.icon-zoom-out:before {
  content: '\e98f';
}

.icon-zoom-in:before {
  content: '\e990';
}

.icon-arrow-down:before {
  content: '\e98e';
}

.icon-mail:before {
  content: '\e97e';
}

.icon-phone:before {
  content: '\e981';
}

.icon-location:before {
  content: '\e982';
}

.icon-terms-of-use:before {
  content: '\e989';
}

.icon-user-management:before {
  content: '\e98a';
}

.icon-company-profile:before {
  content: '\e98b';
}

.icon-user-info:before {
  content: '\e98c';
}

.icon-delete:before {
  content: '\e98d';
}

.icon-download:before {
  content: '\e987';
}

.icon-copy:before {
  content: '\e988';
}

.icon-due-notification:before {
  content: '\e984';
  color: #ffffff;
}

.icon-performance:before {
  content: '\e985';
  color: #656466;
}

.icon-place-order:before {
  content: '\e986';
  color: #656466;
}

.icon-hv-order-request:before {
  content: '\e983';
  color: #656466;
}

.icon-edit1:before {
  content: '\e97b';
}

.icon-boy:before {
  content: '\e97c';
}

.icon-add2:before {
  content: '\e97d';
}

.icon-shipping-address:before {
  content: '\e97f';
}

.icon-upload-file:before {
  content: '\e980';
}

.icon-squares:before {
  content: '\e978';
}

.icon-vintage:before {
  content: '\e979';
}

.icon-tool:before {
  content: '\e977';
}

.icon-interface-1:before {
  content: '\e971';
}

.icon-image:before {
  content: '\e972';
}

.icon-tools:before {
  content: '\e973';
}

.icon-switch:before {
  content: '\e975';
}

.icon-tools-and-utensils:before {
  content: '\e96d';
}

.icon-multimedia-option:before {
  content: '\e96e';
}

.icon-social-media:before {
  content: '\e96f';
}

.icon-interface:before {
  content: '\e970';
}

.icon-down-arrow:before {
  content: '\e96b';
}

.icon-supermarket:before {
  content: '\e96a';
}

.icon-shop:before {
  content: '\e969';
}

.icon-delivery:before {
  content: '\e967';
}

.icon-bell:before {
  content: '\e966';
}

.icon-add-user:before {
  content: '\e962';
}

.icon-tick:before {
  content: '\e943';
}

.icon-file:before {
  content: '\e931';
}

.icon-play-btn:before {
  content: '\e929';
}

.icon-pdf-file:before {
  content: '\e92a';
}

.icon-error:before {
  content: '\e927';
}

.icon-up-arrow-on-circle:before {
  content: '\e926';
}

.icon-success:before {
  content: '\e928';
}

.icon-global:before {
  content: '\e923';
}

.icon-icon:before {
  content: '\e924';
}

.icon-placeholder-filled-point:before {
  content: '\e925';
}

.icon-rating:before {
  content: '\e91d';
}

.icon-map:before {
  content: '\e91e';
}

.icon-id-card:before {
  content: '\e91f';
}

.icon-credit-card:before {
  content: '\e920';
}

.icon-contact-book:before {
  content: '\e921';
}

.icon-comment:before {
  content: '\e922';
}

.icon-clock:before {
  content: '\e91b';
}

.icon-clock-circular-outline:before {
  content: '\e91c';
}

.icon-cancel:before {
  content: '\e91a';
}

.icon-pdf:before {
  content: '\e917';
}

.icon-upload:before {
  content: '\e918';
}

.icon-img:before {
  content: '\e919';
}

.icon-star:before {
  content: '\e916';
}

.icon-expand:before {
  content: '\e004';
}

.icon-calendar:before {
  content: '\e915';
}

.icon-admin-user:before {
  content: '\e913';
}

.icon-dasbhoard:before {
  content: '\e914';
}

.icon-corporate-user:before {
  content: '\e908';
}

.icon-csr:before {
  content: '\e90a';
}

.icon-instructors:before {
  content: '\e90c';
}

.icon-payment-report:before {
  content: '\e90e';
}

.icon-promotions:before {
  content: '\e90f';
}

.icon-student:before {
  content: '\e910';
}

.icon-supervisor-instructor:before {
  content: '\e911';
}

.icon-system-configuration:before {
  content: '\e912';
}

.icon-arrow-top:before {
  content: '\e900';
}

.icon-chevron-down:before {
  content: '\e901';
}

.icon-chevron-up:before {
  content: '\e902';
}

.icon-edit:before {
  content: '\e903';
}

.icon-ellipse-v:before {
  content: '\e904';
}

.icon-email:before {
  content: '\e905';
}

.icon-menu-up:before {
  content: '\e906';
}

.icon-notification:before {
  content: '\e907';
}

.icon-search:before {
  content: '\e909';
}

.icon-widgets:before {
  content: '\e90b';
}

.icon-arrow-left:before {
  content: '\e90d';
}

.icon-chevron-left:before {
  content: '\36';
}

.icon-chevron-right:before {
  content: '\37';
}

.icon-close:before {
  content: '\23';
}

.icon-user:before {
  content: '\e96c';
}

.icon-facebook1:before {
  content: '\e93c';
}

.icon-linkedin:before {
  content: '\e93f';
}

.icon-twitter1:before {
  content: '\e94b';
}

.icon-add:before {
  content: '\e974';
}

.icon-error1:before {
  content: '\e976';
}

.icon-add1:before {
  content: '\e97a';
}

.icon-calendar-new:before {
  content: '\e93a';
}

.icon-camera:before {
  content: '\e939';
}

.icon-schedule-grid-list:before {
  content: '\e937';
}

.icon-schedule-grid-view:before {
  content: '\e938';
}

.icon-dashboard:before {
  content: '\e933';
}

.icon-schedule-management:before {
  content: '\e934';
}

.icon-place-order1:before {
  content: '\e935';
}

.icon-new-customer:before {
  content: '\e936';
}

.icon-home:before {
  content: '\e968';
}

.icon-bin:before {
  content: '\e9ac';
}

.icon-download3:before {
  content: '\e9c7';
}

.icon-instagram:before {
  content: '\ea92';
}

.icon-minus:before {
  content: '\ea0b';
}

.icon-sphere:before {
  content: '\e9c9';
}
