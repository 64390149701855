.footer {
  &__top {
    background-color: $primary;
  }
  &__left {
    &__nav {
      &-item {
        padding-left: rem(12);
        padding-right: rem(12);
        font-size: rem(13);
        letter-spacing: 0.3px;
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
  &__right {
    &__nav {
      &-item {
        padding-left: rem(6);
        padding-right: rem(6);
        font-size: rem(18);
        &:last-child {
          padding-right: 0;
        }
      }
      @include media-breakpoint-down(sm) {
        justify-content: center;
      }
    }
  }
}

.footer-mobile {
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.05);
  .footer-menu {
    color: $gray-600;
    i {
      color: $gray-600;
      font-size: 20px;
    }
    &.active {
      color: $primary;
      i {
        color: $primary;
      }
    }
    .footer-count {
      width: 18px;
      height: 13px;
      line-height: 13px;
      font-family: $font-family-base;
      right: -9px;
      top: -5px;
    }
  }
}
