@import '../../../styles/settings.scss';

// CHECKBOX SWITCH

.switch {
  position: relative;
  display: flex !important;
  align-items: center;
  input {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
    &:checked + .slider {
      // background-color: #2196F3;
    }
    &:focus + .slider {
      // box-shadow: 0 0 1px #2196F3;
    }
    &:checked + .on {
      color: #282828;
    }
    &:checked + .on + .slider + .off {
      color: #c7c7c7;
    }
    &:checked + .label + .slider {
      background-color: $primary;
      border: 1px solid $primary;
    }
    &:checked + .label + .slider:before {
      -webkit-transform: translateX(18px);
      -ms-transform: translateX(18px);
      transform: translateX(18px);
      background-color: $white;
    }
  }
  .slider {
    position: relative;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid $gray-200;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    width: 42px;
    height: 24px;
    display: inline-block;
    // box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.15);
    box-shadow: none;
    background-color: $gray-200;
    &:before {
      position: absolute;
      content: '';
      height: 20px;
      width: 20px;
      left: 1px;
      bottom: 1px;
      background-color: $white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      -webkit-transform: translateX(0px);
      -ms-transform: translateX(0px);
      transform: translateX(0px);
    }
  }
  .slider.round {
    border-radius: 34px;
    &:before {
      border-radius: 34px;
    }
  }
  .label {
    font-size: 12px;
    color: #c7c7c7;
    &.off {
      color: #282828;
      margin-left: 4px;
    }
    &.on {
      margin-right: 4px;
    }
  }
}
