@import '../../assets/sass/custom-variables/variables';

.folder-list {
  li {
    padding: 7px 20px;
    transition: all 0.4s ease-in-out;
    background-color: $white;
    border-radius: 50px;
    margin-bottom: 7px;
    color: #000;
    .tick {
      color: $white;
    }
    &:hover,
    &.selected {
      background-color: $body-bg;
      color: $primary;
      .tick {
        color: $primary;
      }
    }
  }
}

.save-search-popup {
  border: 2px solid $white;
}

.create-folder-overlay {
  background-color: rgba($gray-900, 0.6);
}

.create-folder {
  transform: translateY(100%);
  transition: transform 0.5s ease-in-out;
  &.is-open {
    transform: translateY(0%);
  }
}
