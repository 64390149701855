@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    html[dir='ltr'] {
      .float#{$infix}-left {
        @include float-left;
      }
      .float#{$infix}-right {
        @include float-right;
      }
    }
    html[dir='rtl'] {
      .float#{$infix}-left {
        @include float-right;
      }
      .float#{$infix}-right {
        @include float-left;
      }
    }
    .float#{$infix}-none {
      @include float-none;
    }
  }
}
