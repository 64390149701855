$bootstrap-sass-asset-helper: false !default;
$browser-context: 16; // Default
@function rem($pixels, $context: $browser-context) {
  @return #{$pixels/$context}rem;
}

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1260px,
) !default;

$container-max-widths: (
  sm: 1330px,
);

$body-bg: #f4f7fa;
$primary: #023f88;
$secondary: #6d6e70;
$gray-100: #ebeff3;
$gray-200: #dedede;
$gray-300: #a4a4a4;
$gray-400: #bdbdbd;
$gray-500: #808080;
$gray-600: #606060;
$gray-700: #404040;
$gray-900: #202020;
$border-color: #dce6f0;
$text-muted: #808080;
$white: #fff;
$dark: #000;
$red: #dc3545;
$green: #00a54c;

$font-family-sans-serif: 'Figtree', sans-serif;
$font-family-arabic: 'Almarai', sans-serif;
$font-family-base: 'Figtree', sans-serif;
$font-size-base: 1rem;
$font-size-lg: 1.0625rem;
$font-size-sm: 0.9375rem;

$headings-color: #202020;
$headings-font-weight: 700;

$border-radius-sm: 2px;
$border-radius-lg: 6px;

$shadow: 0px 2px 0px 0px #dce6f0;
$shadow-sm: 0px 0px 4px rgba(0, 0, 0, 0.05);

$input-border-color: #cfd6dd;
$input-placeholder-color: #bababa;
$input-font-size: 14px;
$input-border-radius: 6px;
$input-padding-x: 1rem;
$input-padding-y: 0.6875rem;
$input-padding-x-sm: 0.9375rem;
$input-padding-y-sm: 0.625rem;
$input-line-height-sm: 1.3;
$input-font-size-sm: 14px;
$input-font-weight: 500;

$btn-font-weight: 700;
$btn-font-size: 16px;
$btn-padding-x: 1.75rem;
$btn-padding-y: 0.6875rem;
$btn-border-radius: 6px;
$btn-font-size-sm: 16px;
$btn-border-radius-sm: 6px;
$btn-padding-y-sm: 0.4375rem;
$btn-padding-x-sm: 1.75rem;

$zindex-modal-backdrop: 1030;
