@import '../../../styles/settings.scss';

.react-datepicker-wrapper {
  width: 100%;
  .Input__field {
    background-color: transparent;
  }
}

.react-datepicker {
  border: 1px solid #CFD6DD;
  font-family: inherit;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.04);
  border-radius: 3px;

  &__header {
    border: none;
    background-color: $white;
  }

  &__current-month {
    padding: 10px 0;
  }

  &__current-month,
  &__day-names {
    font-weight: 500;
    font-size: 13px;
  }

  &__navigation {
    top: 13px;

    &-icon:before {
      top: 50%;
      margin-top: -5px;
      border-width: 2px 2px 0 0;
      border-color: $gray-700;
      height: 8px;
      width: 8px;
    }
  }

  &__month-select,
  &__year-select {
    border: 1px solid $input-border-color;
    padding: 8px;
    border-radius: 3px;

    &:focus {
      outline: none;
    }
  }

  &__day,
  &__day-name {
    width: 28px;
    line-height: 24px;
    margin: 2px 4px;
  }

  &__day {
    &--today {
      font-weight: 400;
    }

    &--outside-month {
      color: #BABABA;
    }
  }
  &__day,
  &__month-text,
  &__quarter-text {
    &:hover {
      border-radius: 3px;
      background-color: rgba(2, 128, 128, 0.1);
    }
  }

  &-popper {
    z-index: 9999 !important;

    html[dir="ltr"] & {
      left: 0;
    }
    html[dir="rtl"] & {
      right: 0;
    }

    &[data-placement^=top],
    &[data-placement^=bottom] {
      .react-datepicker__triangle {
        &::before, 
        &::after {
          left: -30px;
        }
      }
    }
    
    &[data-placement^=top] {
      margin-bottom: -18px;

      .react-datepicker__triangle {
        &::before {
          border-top-color: #CFD6DD;
        }
        &::after {
          border-top-color: $white;
        }
      }
    }

    &[data-placement^=bottom] {
      margin-top: -11px;

      .react-datepicker__triangle {
        &::before {
          border-bottom-color: #CFD6DD;
        }
        &::after {
          border-bottom-color: $white;
        }
      }
    }
  }

  &__day--keyboard-selected,
  &__month-text--keyboard-selected,
  &__quarter-text--keyboard-selected,
  &__day--selected,
  &__day--in-selecting-range,
  &__day--in-range,
  &__month-text--selected,
  &__month-text--in-selecting-range,
  &__month-text--in-range,
  &__quarter-text--selected,
  &__quarter-text--in-selecting-range,
  &__quarter-text--in-range {
    background-color: $primary;
    border-radius: 3px;
  }
  
  &__day--keyboard-selected,
  &__month-text--keyboard-selected,
  &__quarter-text--keyboard-selected {
    &:hover {
      background-color: $primary;
    }
  }
}
